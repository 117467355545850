<template>
  <div>
    <div class="gethead">
      <div class="container">
        <div class="homeheader">
          <router-link to="/">
            <span>首页</span>
          </router-link>
          <router-link to="/secondhouse">
            <span>二手房</span>
          </router-link>
          <router-link to="/newhouse">
            <span>新房</span>
          </router-link>
          <router-link to="/secondhouse/sellhouse">
            <span>下载APP</span>
          </router-link>
        </div>
        <div class="contact">
          <img src="../../assets/img/iconuser.png" />
          <div>15336495465</div>
          <div>退出</div>
          <img src="../../assets/img/iconphone.png" />
          <div>热线电话0371 88888 88</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row wanted">
        <router-link to="/">
          <img src="../../assets/img/logo.png" class="jygicon" />
        </router-link>
        <div class="collapse navbar-collapse">
          <div class="nav_item">
            <router-link
              to="/secondhouse/sellhouse"
              :class="{ active: titlename == 'sellhouse' }"
            >
              <span>我要卖房</span>
            </router-link>
            <router-link
              to="/newhouse"
              :class="{ active: titlename == 'buyhouse' }"
            >
              <span>我要求购</span>
            </router-link>
            <router-link
              to="/secondhouse"
              :class="{ active: titlename == 'leasehouse' }"
            >
              <span>我要出租</span>
            </router-link>
            <router-link
              to="/jobwant"
              :class="{ active: titlename == 'rentwant' }"
            >
              <span>我要求租</span>
            </router-link>
            <div class="downloadjyg">
              <img src="../../assets/img/downloadapp.png" />
              下载牛豆豆APP
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="headtitle">发布发售房源</div>
      </div>
      <div class="row">
        <div class="titleexplain">快速推广.专业服务</div>
      </div>
    </div>
    <div class="container center">
      <div class="row">
        <label>所在城市</label>
        <span class="city">郑州</span>
        <span class="switchcity">切换城市</span>
        <div class="location_dialog"></div>
      </div>
      <div class="price">
        <img class="qrcode" src="../../assets/img/qrcode.png" />
        <div class="price_city"><span>郑州市</span> 成交均价</div>
        <div class="price_unit"><span>13146</span> 元/㎡</div>
      </div>
      <div class="row">
        <label>小区名称</label>
        <el-input
          type="text"
          placeholder="请输入小区名称"
          style="width: 80%"
        ></el-input>
      </div>
      <div class="row">
        <label>房屋地址</label>
        <el-input type="text" placeholder="楼栋号"></el-input>
        <el-input type="text" placeholder="单元号"></el-input>
        <el-input type="text" placeholder="门牌号"></el-input>
      </div>
      <div class="house_img">
        <img src="../../assets/img/house_money.png" />
      </div>
      <div class="row">
        <label>面积</label>
        <el-input
          type="text"
          placeholder="请输入大于0的数字"
          style="width: 70%"
        ></el-input>
        <span class="meter">平方米</span>
      </div>
      <div class="row">
        <label>户型</label>
        <el-select v-model="value1">
          <el-option label="公寓" value="0"></el-option>
          <el-option label="单元式" value="1"></el-option>
          <el-option label="复式" value="2"></el-option>
          <el-option label="跃层式" value="3"></el-option>
          <el-option label="花园式" value="4"></el-option>
          <el-option label="小户型" value="5"></el-option>
          <el-option label="按套型" value="6"></el-option>
        </el-select>
        <el-select v-model="value1">
          <el-option label="2室1厅1卫"></el-option>
          <el-option label="2室1厅2卫"></el-option>
          <el-option label="3室1厅1卫"></el-option>
          <el-option label="3室1厅2卫"></el-option>
        </el-select>
        <el-select v-model="value1">
          <el-option label="复式"></el-option>
          <el-option label="跃层式"></el-option>
          <el-option label="花园式"></el-option>
          <el-option label="小户型"></el-option>
          <el-option label="按套型"></el-option>
        </el-select>
      </div>
      <div class="row">
        <label>朝向</label>
        <el-select v-model="value1">
          <el-option label="南朝向"></el-option>
          <el-option label="北朝向"></el-option>
          <el-option label="东朝向"></el-option>
          <el-option label="西朝向"></el-option>
        </el-select>
      </div>
      <div class="row">
        <label>楼层</label>
        <el-input type="text" placeholder="请输入楼层层数"></el-input>
        <label class="totalfloor">总楼层</label>
        <el-select v-model="value1">
          <el-option label="1-9">1-9</el-option>
          <el-option label="10-19">10-19</el-option>
          <el-option label="20-29">20-29</el-option>
          <el-option label="30及以上">30及以上</el-option>
        </el-select>
      </div>
      <div class="row wantpriced">
        <label>期望售价</label>
        <el-input
          type="text"
          placeholder="请输入您期望卖出的价格"
          style="width: 30%"
        ></el-input>
        <span>万元</span>
        <a>不太清楚如何定价?先估个价</a>
      </div>
      <div class="row">
        <label>姓名</label>
        <el-input
          type="text"
          placeholder="我们该如何称呼您"
          style="width: 80%"
        ></el-input>
      </div>
      <div class="row">
        <label>手机号码</label>
        <el-input
          type="text"
          placeholder="您的联系方式,方便我们及时与您联系"
          style="width: 80%"
        ></el-input>
      </div>
      <div class="row">
        <label>物业费</label>
        <el-input
          type="text"
          placeholder="请输入大于0的数字"
          style="width: 30%"
        ></el-input>
        <span class="rmb">元</span>
      </div>
      <div class="row">
        <label>图片上传</label>
        <div id="picker">
          <div id="">
            <button type="button" class="btn btn-default">浏览</button>
          </div>
        </div>
      </div>
      <div class="row activity">
        <label>房源特色</label>
        <div>
          <el-checkbox>满五</el-checkbox>
          <el-checkbox>唯一</el-checkbox>
          <el-checkbox>地铁房</el-checkbox>
          <el-checkbox>公园</el-checkbox>
          <el-checkbox>医院</el-checkbox>
          <el-checkbox>电梯</el-checkbox>
          <el-checkbox>商场</el-checkbox>
          <el-checkbox>学校</el-checkbox>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row submit">
        <el-button type="primary">提交委托</el-button>
      </div>
    </div>
    <footer>
      <div>
        <span>服务账号用户协议</span>
        <span>|</span>
        <span>关于服务账号隐私声明</span>
        <span>|</span>
        <span>常见问题</span>
      </div>
      <div>
        <span>@2019 凯业房地产估价咨询有限公司</span>
        <span>版权所有</span>
        <span>豫 00-000000000 号</span>
        <span>|</span>
        <span>豫备 00000002 号</span>
      </div>
    </footer>
  </div>
</template>

<script>
// import {detail} from '@/api/housedetail'
export default {
  data() {
    const self = this;
    return {
      titlename: 'sellhouse',
      value1: '',
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.gethead {
  background-color: #303035;
  width: 100%;
  height: 40px;
}
.container {
  width: 1040px;
  margin: 0 auto;
}
.homeheader {
  height: 50px;
  float: left;
}
.homeheader a {
  text-decoration: none;
  text-align: center;
  color: #9e9fa0;
  font-size: 15px;
  line-height: 40px;
  padding: 10px 10px;
}
.homeheader a:hover {
  background-color: #eeeeee;
  color: black;
}
.contact {
  float: right;
  padding-top: 10px;
  color: #f1f1f2;
  font-size: 12px;
}
.contact img,
.contact div {
  float: left;
  margin: 0 5px;
  line-height: 16px;
}
.contact img {
  background-color: #6f6f73;
  width: 16px;
  border-radius: 50%;
  height: 16px;
}
.collapse .nav_item a {
  text-decoration: none;
  color: black;
  text-align: center;
  font-size: 15px;
  margin-right: 25px;
  text-decoration: none;
}
.collapse .nav_item .active {
  color: #0286ed;
}
.collapse .nav_item {
  margin-left: 50px;
  margin-top: 10px;
}
.navbar-nav > li > a {
  color: #9e9fa0;
  padding-top: 10px;
  height: 40px;
}
.navbar-nav a:hover {
  color: black;
}
.download {
  float: left;
  padding: 10px 0 0 30px;
  color: #9e9fa0;
}

.wanted {
  margin-top: 20px;
  font-size: 15px;
}
.wanted .jygicon {
  float: left;
  width: 100px;
  height: 30px;
}
.wanted .navbar-collapse {
  float: left;
  width: 800px;
}
.wanted .navbar-collapse a {
  color: black;
}
.wanted .navbar-collapse a:hover {
  color: #41b4fb;
}
.wanted .navbar-collapse > .navbar-nav .active {
  color: #41b4fb;
}
.wanted .downloadjyg {
  color: #66747c;
  float: right;
}
.headtitle {
  padding-top: 30px;
  font-size: 22px;
  line-height: 30px;
  clear: both;
  text-align: center;
}
.titleexplain {
  color: #aaaaab;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 80px;
}
.center {
  font-size: 14px;
  padding-left: 100px;
}
.center .row {
  display: block;
  width: 660px;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
  line-height: 30px;
  margin-bottom: 10px;
}
.center .row label {
  font-size: 14px;
  width: 100px;
}
.center .row .el-input,
.center .row .el-select {
  padding-left: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 150px;
  height: 30px;
  line-height: 30px;
  border: 0px;
  outline: none;
}
>>> .center .row .el-input .el-input__inner {
  border: 0px;
}
.center .row select {
  background-color: #f5f5f6;
}
.center .city {
  display: inline-block;
  padding-left: 10px;
}
.center .switchcity {
  color: #41b4fb;
  margin-left: 50px;
  cursor: pointer;
}
/* .location_dialog::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

.location_dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #87ceeb;
}

.location_dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
} */
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* .location_dialog {
  display: none;
  top: 22%;
  left: 37%;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: 550px;
  padding: 10px 20px;
  min-height: 210px;
  max-height: 350px;
  overflow-y: auto;
} */

/* #myTabContent .tab-pane span {
  line-height: 60px;
  cursor: pointer;
  font-size: 12px;
  float: left;
  display: inline-block;
  width: 80px;
  padding-top: 8px;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: pointer;
}

#myTabContent .tab-pane span:hover {
  color: #007def;
} */

/* .fl {
  float: left;
} */

/* .pick-left {
  font-size: 14px;
  color: #498ad5;
  white-space: nowrap;
  padding-top: 3px;
}

.pick-right {
  width: 460px;
  padding-left: 20px;
} */

/* .clearfix > li {
  clear: both;
} */

.center .price {
  width: 300px;
  height: 60px;
  position: relative;
  background-color: #101d37;
  float: right;
  color: #fff;
  margin-top: -50px;
  padding: 8px 8px 15px 15px;
}
.center .qrcode {
  float: right;
}
.center .house_img {
  position: relative;
  float: right;
  margin: -50px 100px 0 0;
}

.center .price_city {
  margin-top: 10px;
}
.center .price_unit {
  margin-top: 5px;
  font-size: 22px;
}
.center .meter {
  float: right;
  margin-right: 20px;
  font-weight: 600;
}
.center .totalfloor {
  margin-left: 100px;
  width: 50px !important;
}
.center .wantpriced span,
.center .rmb {
  margin: 0 50px;
  font-weight: 600;
}
.center .wantpriced a {
  text-decoration: none;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: #23baf3;
}
.center #picker div label {
  background-color: #fff;
}
.center .webuploader-pick {
  background-color: #fff;
}
.center .row > label:nth-child(1) {
  float: left;
}
.center .activity {
  padding-bottom: 80px;
}
.center .activity div {
  display: inline-block;
  float: left;
  width: 450px;
}
.submit {
  margin: 30px 0 60px 0;
  text-align: center;
}
.btn-primary {
  background-color: #069ffd;
  padding: 13px 60px;
  border: 0;
}
footer {
  background-color: #ebebeb;
  width: 100%;
  height: 150px;
  padding-top: 20px;
}
footer div {
  text-align: center;
  color: #999999;
}
footer div span {
  margin: 0 5px;
}
</style>